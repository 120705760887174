<template>
  <div class="content">
    <div class="triangle_left" v-on:click="ToMenu()"></div><div class="item"><h4>{{dict.points}}</h4></div>
	
	<div id=content>
	    <h5 id="points_h">{{dict.you_have}}&nbsp;{{ points }}</h5>
		
        <div v-if="receive_points" id="receive_points" v-on:click="receivePoints()">{{dict.get_points}}</div>
		
		<div v-if="responsible_game" id="responsible_game" v-on:click="buyPoints()" style="background-color:#00796B;color:#fff;">{{dict.buy}}</div>
		
		<h5 v-if="responsible_game" id="coins_h">
		<svg version="1.1" id="card" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
<g><path d="M11,18v-1.745c0-0.087,0.016-0.17,0.026-0.255H7.255C7.114,16,7,16.114,7,16.255V18H11z"/>	<rect x="20" y="39" width="7" height="4"/><path d="M13,16.255v9.49C13,25.886,13.114,26,13.255,26h1.49C14.886,26,15,25.886,15,25.745v-5.49C15,19.012,16.012,18,17.255,18
		h3.49C20.886,18,21,17.886,21,17.745v-1.49C21,16.114,20.886,16,20.745,16h-7.49C13.114,16,13,16.114,13,16.255z"/>
	<rect x="7" y="39" width="7" height="4"/><rect x="7" y="20" width="4" height="2"/>
	<path d="M11,25.745V24H7v1.745C7,25.886,7.114,26,7.255,26h3.771C11.016,25.915,11,25.832,11,25.745z"/><path d="M17,24v1.745c0,0.087-0.016,0.17-0.026,0.255h3.771C20.886,26,21,25.886,21,25.745V24H17z"/>
	<path d="M17.255,20C17.114,20,17,20.114,17,20.255V22h4v-2.026C20.915,19.984,20.832,20,20.745,20H17.255z"/>
	<rect x="33" y="39" width="7" height="4"/><rect x="46" y="39" width="7" height="4"/>
	<path d="M55.783,8H4.217C1.892,8,0,9.892,0,12.217v35.566C0,50.108,1.892,52,4.217,52h51.566C58.108,52,60,50.108,60,47.783V12.217
		C60,9.892,58.108,8,55.783,8z M32,14h9c0.553,0,1,0.448,1,1s-0.447,1-1,1h-9c-0.553,0-1-0.448-1-1S31.447,14,32,14z M40,20
		c0,0.552-0.447,1-1,1h-2c-0.553,0-1-0.448-1-1s0.447-1,1-1h2C39.553,19,40,19.448,40,20z M32,19h1c0.553,0,1,0.448,1,1
		s-0.447,1-1,1h-1c-0.553,0-1-0.448-1-1S31.447,19,32,19z M5,16.255C5,15.012,6.012,14,7.255,14h6h7.49
		C21.988,14,23,15.012,23,16.255v1.49v8C23,26.988,21.988,28,20.745,28h-6h-1.49h-6C6.012,28,5,26.988,5,25.745V16.255z M16,45H5v-8
		h11V45z M29,45H18v-8h11V45z M42,45H31v-8h11V45z M42,20c0-0.552,0.447-1,1-1h1c0.553,0,1,0.448,1,1s-0.447,1-1,1h-1
		C42.447,21,42,20.552,42,20z M55,45H44v-8h11V45z M47,20c0-0.552,0.447-1,1-1h2c0.553,0,1,0.448,1,1s-0.447,1-1,1h-2
		C47.447,21,47,20.552,47,20z M54.71,20.71C54.52,20.89,54.26,21,54,21c-0.271,0-0.521-0.11-0.71-0.29C53.109,20.52,53,20.26,53,20
		c0-0.26,0.109-0.52,0.29-0.71c0.37-0.37,1.04-0.37,1.42,0C54.89,19.48,55,19.74,55,20C55,20.26,54.89,20.52,54.71,20.71z M54,16h-9
		c-0.553,0-1-0.448-1-1s0.447-1,1-1h9c0.553,0,1,0.448,1,1S54.553,16,54,16z"/>
</g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
		
		&nbsp;{{dict.balance}}&nbsp;{{ coins }}&nbsp;coins</h5>
		
		<div v-if="responsible_game" id="withdraw_money" v-on:click="withdrawMoney()" style="background-color:#00796B;color:#fff;">{{dict.withdraw}}</div>
		
		<div v-if="process"><b>{{countDown}}</b></div>
		<div v-if="process" id="external_content" v-html="field_external_content"></div>
	</div>
  </div>  
</template>
<script>
import axios from 'axios'

export default {
  data(){
      return {
	    //доступы
		image: '',
		id: '',
        name: '', 
        token: '',
		email: '',
        //end
        points: 0,
		coins: 0,
		receive_points: true,
		responsible_game: false, //ответственная игра
		field_external_content: '', //поле с внешнийм контентом
		process: false, //показ рекламы или покупка баллов
		//счетчик показов
		countDown: 0,
		counter: 20,
		tmp_points: 0,
		real_game: false, //флаг запуска игры на coins
		//словарь
        dict: {},
		
      }
  },
  methods: {
      ToMenu: function () {
         this.$router.push('/')
      },
	  receivePoints() {
	     this.receive_points = false
		 this.process = true		 
		  
		 let data = JSON.stringify({'q' : 'get_reclama', data: { 'name' : this.name, 'id' : this.id, 'token' : this.token }}) 
         axios.post(this.$hostname+'user-cards', "data="+data)
         .then(response => { 
            this.field_external_content = response.data.reclama		
                       
         })
         .catch(err => {
            console.log(err)
         })
		 
		 
		 this.countDown = this.counter
		 this.countDownTimer()
		 this.$metrika.reachGoal('click_reclama')
	  },
	  buyPoints() {
	     this.$router.push('/oferta')
	  },
	  withdrawMoney() {
	     this.$router.push('/withdraw')
	  },
	  countDownTimer() {
	    if(this.countDown > 0) {
            setTimeout(() => {
                this.countDown--
                this.countDownTimer()				
            }, 1500)
        }else{
		    let data = JSON.stringify({'q' : 'add_points', data: { 'name' : this.name, 'id' : this.id, 'token' : this.token }}) 
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => { 
                		
                this.points = response.data.points
		        this.receive_points = true
                this.process = false       
            })
            .catch(err => {
                console.log(err)
            })		
		
		    			
		}
		 
      },
	  
	  isEmpty: function (obj) {
           for (let key in obj) {
            // если тело цикла начнет выполняться - значит в объекте есть свойства
            return false;
           }
           return true;
      },
	  
	  
  },
  created() {
        
     /* доступы */
          
     if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
     }
     
     if(localStorage.getItem('name') != null){
        this.name = localStorage.getItem('name')
     }
	 
	 if(localStorage.getItem('id') != null){
        this.id = localStorage.getItem('id')
     }
	 
	 if(localStorage.getItem('image') != null){
        this.image = localStorage.getItem('image')
     }
	 
	 if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
		this.responsible_game = true
     }
	 
	 this.dict = this.$store.getters.getLang
	 if(this.isEmpty(this.dict)){ //если словарь пустой. запрашиваем
	        const data = JSON.stringify({'q' : 'get_lang', data: {'token' : this.token}})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {
		        if (typeof response.data.lang !== 'undefined') {
		                                			  
				    /*записываем полученный словарь*/
				    this.$store.commit('setLang', response.data.lang)				  
				    this.dict = this.$store.getters.getLang                 			  
				  
				    /*end*/		  
               
		        }
		   
            })
            .catch(err => {
                console.log(err)
            })
	 }
	 /*end*/
	 
	 
     const data = JSON.stringify({'q' : 'get_points', data: { 'name' : this.name, 'id' : this.id, 'token' : this.token, 'real_game' : this.real_game, }})  
     axios.post(this.$hostname+'user-cards', "data="+data)
        .then(response => {
		   if (typeof response.data.status_game !== 'undefined' && response.data.status_game == 0) {
			    this.$router.push('/headband')
		   }
		   
		   if (typeof response.data.status_rg_game !== 'undefined' && response.data.status_rg_game == 0) {
			    this.responsible_game = false
		   }
			
           if(response.data != false){
              this.points = response.data.points
			  this.coins = response.data.coins
			  if (typeof response.data.email !== 'undefined' && this.email == '') {
			    localStorage.setItem('email', response.data.email)
				this.email = response.data.email
				if (typeof response.data.status_rg_game !== 'undefined' && response.data.status_rg_game == 0) {
			       this.responsible_game = false
		        }else{
				   this.responsible_game = true
				}   
		      }
		   }else{
		      this.token = ''
              localStorage.setItem('token', '')
           }		   
		   
        })
        .catch(err => {
           console.log(err)
        })
     
        
  },
              
} 

</script>     
