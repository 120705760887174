<template>
  <div class="content">
    <div class="triangle_left" v-on:click="ToMenu()"></div><div class="item"><h4>{{dict.withdraw}}</h4></div>
	
	<div id=withdraw>
	    <h5 class="exchange_h">
		<svg version="1.1" id="card" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
<g><path d="M11,18v-1.745c0-0.087,0.016-0.17,0.026-0.255H7.255C7.114,16,7,16.114,7,16.255V18H11z"/>	<rect x="20" y="39" width="7" height="4"/><path d="M13,16.255v9.49C13,25.886,13.114,26,13.255,26h1.49C14.886,26,15,25.886,15,25.745v-5.49C15,19.012,16.012,18,17.255,18
		h3.49C20.886,18,21,17.886,21,17.745v-1.49C21,16.114,20.886,16,20.745,16h-7.49C13.114,16,13,16.114,13,16.255z"/>
	<rect x="7" y="39" width="7" height="4"/><rect x="7" y="20" width="4" height="2"/>
	<path d="M11,25.745V24H7v1.745C7,25.886,7.114,26,7.255,26h3.771C11.016,25.915,11,25.832,11,25.745z"/><path d="M17,24v1.745c0,0.087-0.016,0.17-0.026,0.255h3.771C20.886,26,21,25.886,21,25.745V24H17z"/>
	<path d="M17.255,20C17.114,20,17,20.114,17,20.255V22h4v-2.026C20.915,19.984,20.832,20,20.745,20H17.255z"/>
	<rect x="33" y="39" width="7" height="4"/><rect x="46" y="39" width="7" height="4"/>
	<path d="M55.783,8H4.217C1.892,8,0,9.892,0,12.217v35.566C0,50.108,1.892,52,4.217,52h51.566C58.108,52,60,50.108,60,47.783V12.217
		C60,9.892,58.108,8,55.783,8z M32,14h9c0.553,0,1,0.448,1,1s-0.447,1-1,1h-9c-0.553,0-1-0.448-1-1S31.447,14,32,14z M40,20
		c0,0.552-0.447,1-1,1h-2c-0.553,0-1-0.448-1-1s0.447-1,1-1h2C39.553,19,40,19.448,40,20z M32,19h1c0.553,0,1,0.448,1,1
		s-0.447,1-1,1h-1c-0.553,0-1-0.448-1-1S31.447,19,32,19z M5,16.255C5,15.012,6.012,14,7.255,14h6h7.49
		C21.988,14,23,15.012,23,16.255v1.49v8C23,26.988,21.988,28,20.745,28h-6h-1.49h-6C6.012,28,5,26.988,5,25.745V16.255z M16,45H5v-8
		h11V45z M29,45H18v-8h11V45z M42,45H31v-8h11V45z M42,20c0-0.552,0.447-1,1-1h1c0.553,0,1,0.448,1,1s-0.447,1-1,1h-1
		C42.447,21,42,20.552,42,20z M55,45H44v-8h11V45z M47,20c0-0.552,0.447-1,1-1h2c0.553,0,1,0.448,1,1s-0.447,1-1,1h-2
		C47.447,21,47,20.552,47,20z M54.71,20.71C54.52,20.89,54.26,21,54,21c-0.271,0-0.521-0.11-0.71-0.29C53.109,20.52,53,20.26,53,20
		c0-0.26,0.109-0.52,0.29-0.71c0.37-0.37,1.04-0.37,1.42,0C54.89,19.48,55,19.74,55,20C55,20.26,54.89,20.52,54.71,20.71z M54,16h-9
		c-0.553,0-1-0.448-1-1s0.447-1,1-1h9c0.553,0,1,0.448,1,1S54.553,16,54,16z"/>
</g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
		
		&nbsp;{{dict.balance}}&nbsp;{{ coins_h }}&nbsp;coins</h5>
		
		
		
		<h5 class="exchange_h" ><svg version="1.1" id="exchange" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 297 297" style="enable-background:new 0 0 297 297;" xml:space="preserve">
<g><path d="M50.169,100.819l-21.624,19.44v56.482l21.624,19.44h196.663l21.623-19.44v-56.482l-21.623-19.44H50.169z M82.248,156.531
		H49.123c-4.435,0-8.031-3.596-8.031-8.03c0-4.435,3.596-8.03,8.031-8.03h33.126c4.435,0,8.03,3.596,8.03,8.03
		C90.279,152.935,86.683,156.531,82.248,156.531z M148.5,181.626c-18.295,0-33.126-14.831-33.126-33.126
		c0-18.295,14.831-33.126,33.126-33.126s33.126,14.831,33.126,33.126C181.626,166.795,166.795,181.626,148.5,181.626z
		 M255.907,148.5c0,4.435-3.596,8.03-8.03,8.03h-33.126c-4.435,0-8.03-3.596-8.03-8.03c0-4.435,3.596-8.03,8.03-8.03h33.126
		C252.311,140.47,255.907,144.065,255.907,148.5z"/>
	<path d="M286.441,71.541H53.99c4.855-16.148,19.817-27.945,37.48-27.945h62.144l-6.336,5.367c-4.23,3.584-4.754,9.917-1.171,14.148
		c1.986,2.344,4.816,3.55,7.665,3.55c2.292,0,4.598-0.781,6.484-2.378l27.229-23.065c2.238-1.897,3.535-4.678,3.55-7.612
		c0.014-2.934-1.256-5.727-3.476-7.644l-27.229-23.52c-4.199-3.626-10.535-3.16-14.158,1.034
		c-3.624,4.196-3.161,10.535,1.034,14.158l6.814,5.886H91.47c-28.793,0-52.84,20.698-58.127,48.021H10.559
		c-5.543,0-10.038,4.495-10.038,10.038v133.841c0,5.544,4.495,10.038,10.038,10.038h232.45
		c-4.855,16.148-19.816,27.945-37.48,27.945h-62.144l6.336-5.367c4.23-3.584,4.754-9.917,1.172-14.148
		c-3.584-4.229-9.918-4.752-14.148-1.171l-27.229,23.065c-2.238,1.897-3.535,4.678-3.55,7.612c-0.014,2.934,1.256,5.727,3.476,7.644
		l27.229,23.52c1.898,1.639,4.233,2.442,6.557,2.442c2.816,0,5.616-1.178,7.601-3.476c3.624-4.196,3.161-10.535-1.034-14.158
		l-6.814-5.886h62.548c28.793,0,52.84-20.699,58.127-48.021h22.784c5.543,0,10.038-4.495,10.038-10.038V81.579
		C296.479,76.036,291.984,71.541,286.441,71.541z M284.515,180.32c0,2.278-0.967,4.449-2.661,5.972l-26.574,23.891
		c-1.474,1.325-3.387,2.059-5.369,2.059H47.09c-1.982,0-3.895-0.733-5.369-2.059l-26.575-23.891
		c-1.694-1.523-2.661-3.694-2.661-5.972v-63.64c0-2.278,0.968-4.449,2.661-5.972l26.575-23.891c1.474-1.325,3.387-2.059,5.369-2.059
		H249.91c1.982,0,3.895,0.733,5.369,2.059l26.574,23.891c1.694,1.523,2.661,3.694,2.661,5.972V180.32z"/>
</g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
        1 ton / {{ exchange }} coins
		</h5>
		
		<h5 class="exchange_h" >{{ dict.commission }} 3%</h5>
		<div id="exchange_ton"><input  v-model="sum_exchange" type="number" placeholder="ton" min="0" :max="coins"/></div>
		
		<h5 class="exchange_h" v-if="sum_ton > 0">{{ sum_ton }} ton</h5>
		
		<div v-if="sum_ton > 0" id="exchange_money" v-on:click="exchangeMoney()" >{{dict.withdraw}}</div>
		
		<div style="margin: 0 auto;text-align: center;"><h5 class="exchange_h" v-if="sum_ton > 0 && message.length > 0">{{ message }}</h5></div>
	</div>
  </div>  
</template>
<script>
import axios from 'axios'

export default {
  data(){
      return {
	    //доступы
		image: '',
		id: '',
        name: '', 
        token: '',
		email: '',
		coins: 0,
		coins_h: 0,
		exchange: 0,
		sum_exchange: 0,
		sum_ton: 0,
		message: '',
        //end
        field_external_content: '', //поле с внешним контентом	
        //словарь
        dict: {},
		status_accept: 'not_accepted'
      }
  },
  methods: {
      ToMenu: function () {
         this.$router.push('/points')
      },
	  
	  isEmpty: function (obj) {
         for (let key in obj) {
            // если тело цикла начнет выполняться - значит в объекте есть свойства
            return false;
         }
         return true;
      },
	  
	  changeCoinsH: function () {
	     if(this.sum_exchange > this.coins){
		    this.sum_exchange = this.coins
		 }
		 var commission 
		 var conversion
		 commission = Math.round(3 * this.sum_exchange / 100)
		 this.sum_ton = (this.sum_exchange - commission) / this.exchange
		 
	     this.coins_h = this.coins - this.sum_exchange
		 /* this.sum_ton = this.sum_exchange / this.exchange */
	  },
	  
	  exchangeMoney: function () {
	     const data = JSON.stringify({'q' : 'withdraw_money', data: { 'name' : this.name, 'id' : this.id, 'token' : this.token, 'email' : this.email, 'sum_exchange' : this.sum_exchange }})  
         axios.post(this.$hostname+'user-cards', "data="+data)
        .then(response => {
		    
            this.coins = response.data.coins
			this.coins_h = response.data.coins
            this.exchange = response.data.exchange			
		    this.message = response.data.message
        })
        .catch(err => {
           console.log(err)
        })
	  
	  },
	  
	  
	  
  },
  created() {
     //блокируем для десктопа 
     if ( !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ){
        window.location.href = 'https://www.minerball.one';
     } 
  
     /* доступы */
          
     if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
     }
     
     if(localStorage.getItem('name') != null){
        this.name = localStorage.getItem('name')
     }
	 
	 if(localStorage.getItem('id') != null){
        this.id = localStorage.getItem('id')
     }
	 
	 if(localStorage.getItem('image') != null){
        this.image = localStorage.getItem('image')
     }
	 
	 if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
		this.responsible_game = true
     }
	 
	 if(this.$store.getters.getAccept != null && this.$store.getters.getAccept != 'accepted'){
        this.status_accept = 'not_accepted'
		this.$router.push('/oferta')
     }
	 
	 this.dict = this.$store.getters.getLang
	 if(this.isEmpty(this.dict)){ //если словарь пустой. запрашиваем
	        const data = JSON.stringify({'q' : 'get_lang', data: {'token' : this.token}})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {
		        if (typeof response.data.lang !== 'undefined') {
		                                			  
				    /*записываем полученный словарь*/
				    this.$store.commit('setLang', response.data.lang)				  
				    this.dict = this.$store.getters.getLang                 			  
				  
				    /*end*/		  
               
		        }
		   
            })
            .catch(err => {
                console.log(err)
            })
	 }
	 /*end*/
	 
	 const data = JSON.stringify({'q' : 'get_coins', data: { 'name' : this.name, 'id' : this.id, 'token' : this.token, 'email' : this.email }})  
     axios.post(this.$hostname+'user-cards', "data="+data)
        .then(response => {
		    if (typeof response.data.status_game !== 'undefined' && response.data.status_game == 0) {
			    this.$router.push('/headband')
			}
            this.coins = response.data.coins
			this.coins_h = response.data.coins
            this.exchange = response.data.exchange			
		   
        })
        .catch(err => {
           console.log(err)
        })
     
     
        
  },
  watch: {
     sum_exchange: function () {
         this.changeCoinsH()
     },
     
	 
  },
              
} 

</script>     
