import { render, staticRenderFns } from "./Skins.vue?vue&type=template&id=244fcfc8&"
import script from "./Skins.vue?vue&type=script&lang=js&"
export * from "./Skins.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports