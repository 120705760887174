import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Axios from 'axios'
import router from './router'
//yandex metrika
import VueYandexMetrika from 'vue-yandex-metrika'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './registerServiceWorker'
library.add(faHatWizard)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueYandexMetrika, {
    id: 89172046,
	router: router,
	env: "production",
    //env: process.env.NODE_ENV,	//"production",
	//debug: true,
})


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.prototype.$http = Axios;
Vue.prototype.$store = store;
Vue.prototype.$hostname = 'https://myhap.ru/';
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
  //Vue.prototype.$http.defaults.headers.common['Token'] = token
}

Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
