<template>
    <div>		
      <form class="login" @submit.prevent="login" v-show="login_form">
         <h4>{{dict.hello}}</h4>
         <p>{{dict.name_your_player}}</p>
         <p><input required v-model="nickname" type="text" minlength="3" maxlength="11"/></p>
         <p><button type="submit">{{dict.start}}</button></p>
      </form>
      <div id="prewiev" v-show="prewiev">
      </div>	  
    </div>
</template>

<script>
import axios from 'axios'

export default {
  data(){
      return {
        nickname : "",
        login_form: false,
        prewiev: true,
        dict: {},		
      }
  },
  methods: {
      login: function () {
        let nickname = this.nickname 
        this.$store.dispatch('login', {'q' : 'auth_user', 'data' : {'nickname' : nickname}})
       .then(() => this.$router.push('/'))
       .catch(err => console.log(err))
      },
	  
	  isEmpty: function (obj) {
         for (let key in obj) {
            // если тело цикла начнет выполняться - значит в объекте есть свойства
            return false;
         }
         return true;
      },
	  
	  
  },
  
  created() {
        
      this.dict = this.$store.getters.getLang
      const data = JSON.stringify({'q' : 'get_lang'})  
      axios.post(this.$hostname+'user-cards', "data="+data)
      .then(response => {
		   if (typeof response.data.lang !== 'undefined') {
		       setTimeout(() => {
                    			  
				    /*записываем полученный словарь*/
				    this.$store.commit('setLang', response.data.lang)				  
				    this.dict = this.$store.getters.getLang                  			  
				  
				    /*end*/
				  
				    this.prewiev = false
			        this.login_form = true
				  
               }, 2000 ); 
               
		   }
		   
      })
      .catch(err => {
          console.log(err)
      })
  },
  
  
              
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#prewiev{
   background-image:url("https://myhap.ru/img/headband.png");
   width: 100%;
   height: 100vh;
   background-size: 100%;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
