<template>
  <div class="content">
     <div class="triangle_left" v-on:click="ToMenu()"></div><div class="item"><h4>{{dict.skin}}</h4></div>
	 <div id="win"><h4>{{count_win}} {{dict.mes_count_win}}</h4></div>
	 <div id=skins>
	    <carousel-3d :perspective=35 :count="countSl" :display="displaySl" :animationSpeed=500  :width=250 :minSwipeDistance=10 :startIndex="index" :height=250 :border=0 >
		    <slide :index="k" v-for="(v, k) in skins">
			    <div>
				   <img alt="" class="round" :id="k" :src="v" v-on:click="touchSlider(k)">
				</div>   
		    </slide>            		
	    </carousel-3d>
	 </div>
     <div id="collection_info"><h4>{{dict.collect_info}}</h4></div>
  </div>
</template>

<script>
import axios from 'axios'
import { Carousel3d, Slide } from 'vue-carousel-3d'

export default {
  components: {
     Carousel3d,
     Slide
  },
  data(){
      return {
	     //доступы
		image: '',
		id: '',
        name: '', 
        token: '',
		email: '',
		count_win: '', 
        //end
		//словарь
        dict: {},
		index: 0, //индекс начального слайда
		countSl: 5, //кол-во слайдов. необходимо для работы слайдеры. подгружать
		displaySl: 3, //кол-во слайдов которое одновременно отображается. подгружать
		temp_index: null,
		skins: {
		   0:'0',
		   1:'1',
		   2:'2',
		   3:'3',
		   4:'4'
		   },
		
	  }
   
  },
  
  methods: {
      ToMenu: function () {
         this.$router.push('/settings')
      },
	  
	  isEmpty: function (obj) {
         for (let key in obj) {
            // если тело цикла начнет выполняться - значит в объекте есть свойства
            return false;
         }
         return true;
      },
	  
	  touchSlider(index){
	     //console.log(index)
		 let e
		 let ee
	     e = document.getElementById(index)
		 e.style.filter = "drop-shadow(0 0 15px green)"
		 if(this.temp_index != null){ //снимаем с предыдущего блока подсветку
		    ee = document.getElementById(this.temp_index)
			ee.style.filter = "drop-shadow(0 0 0px green)"			
		 }
         this.temp_index = index
		 //отсылаем выбранную картинку на сервер
		 const data = JSON.stringify({'q' : 'set_avatar', data: {'token' : this.token, 'avatar' : this.skins[index]}})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {
		        if (typeof response.data.avatar !== 'undefined') {
		                                			  
				    localStorage.setItem('image', response.data.avatar)
					this.image = response.data.avatar	
               
		        }
		   
            })
            .catch(err => {
                console.log(err)
            })
	  }
	  
   },	  
	  
   created() {
        
     /* доступы */
          
     if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
     }
     
     if(localStorage.getItem('name') != null){
        this.name = localStorage.getItem('name')
     }
	 
	 if(localStorage.getItem('id') != null){
        this.id = localStorage.getItem('id')
     }
	 
	 if(localStorage.getItem('image') != null){
        this.image = localStorage.getItem('image')
     }
	 
	 if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
		this.responsible_game = true
     }
	 
	 this.dict = this.$store.getters.getLang
	 if(this.isEmpty(this.dict)){ //если словарь пустой. запрашиваем
	        const data = JSON.stringify({'q' : 'get_lang', data: {'token' : this.token}})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {
		        if (typeof response.data.lang !== 'undefined') {
		                                			  
				    /*записываем полученный словарь*/
				    this.$store.commit('setLang', response.data.lang)				  
				    this.dict = this.$store.getters.getLang                 			  
				     
				    /*end*/		  
               
		        }
		   
            })
            .catch(err => {
                console.log(err)
            })
	 }
	 
	 const dt = JSON.stringify({'q' : 'get_skins', data: {'token' : this.token}})  
            axios.post(this.$hostname+'user-cards', "data="+dt)
            .then(response => {
		        if (typeof response.data.skins !== 'undefined' ) {
		                                			  
				    /*записываем полученный массив скинов*/
				    this.skins = response.data.skins 
					
					if (typeof response.data.len !== 'undefined' ) {
                        this.countSl = response.data.len
						this.count_win = response.data.rating
					}	
				    /*end*/		  
               
		        }
		   
            })
            .catch(err => {
                console.log(err)
            })
  
  },
  
  watch: {
  
  
  },
  
  
}

</script>  