import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    name : localStorage.getItem('name') || '',
	id : localStorage.getItem('id') || '',
	image : localStorage.getItem('image') || 'https://myhap.ru/icons/ava.png',
	id_game: localStorage.getItem('id_game') || '',
	ids_players: localStorage.getItem('ids_players') || [],
	names_players: localStorage.getItem('names_players') || [],
	image_another_player: localStorage.getItem('image_another_player') || '',
	your_image: localStorage.getItem('your_image') || '',
	cashbox: localStorage.getItem('cashbox') || '',
	email: localStorage.getItem('email') || '',
	lang: {},
	user_data: {},
	status_accept: localStorage.getItem('status_accept') || 'not_accepted',
	win_pin: 0,
  },
  
  mutations: {
  	  auth_request(state){
         state.status = 'loading'
      },
      auth_success(state, token){
         state.status = 'success'
         state.token = token         
      },
      auth_error(state){
         state.status = 'error'
      },
      logout(state){
         state.status = ''
         state.token = ''
      }, 
      setLang(state, obj){
         state.lang = obj
      },
      setAccept(state, status_accept){
         state.status_accept = status_accept
      },
      setUserData(state, obj){
		 state.user_data = obj
      },
      setWinPin(state){
		 state.win_pin = 1
      }		  
	  
  },
  
  actions: {
  	  login({commit}, user){
       return new Promise((resolve, reject) => {
          commit('auth_request')
		  axios.defaults.headers.common['Authorization'] = 'undefined'
          const data = JSON.stringify(user)
          axios.post('https://myhap.ru/user-cards', 'data='+data)
          .then(resp => {
             const token = resp.data.token
             const user = resp.data.user
             			 
             localStorage.setItem('token', token)
			 localStorage.setItem('name', user.name)
			 localStorage.setItem('id', user.id)
			 localStorage.setItem('image', user.image)
			 if(typeof user.email !== 'undefined'){
				localStorage.setItem('email', user.email) 
			 }	 
             axios.defaults.headers.common['Authorization'] = token
             commit('auth_success', token)
             resolve(resp)
          })
          .catch(err => {
             commit('auth_error')
             localStorage.removeItem('token')
             reject(err)
          })
       })
    },
    logout({commit}){
       return new Promise((resolve, reject) => {
          commit('logout')
          localStorage.removeItem('token')
          delete axios.defaults.headers.common['Authorization']
          resolve()
       })
    },   
  	
  },
  getters : {
  	    isLoggedIn: state  => !!state.token,
        authStatus: state  => state.status,
        getLang:    state  => state.lang,
        getAccept: state   => state.status_accept,
        getUserData: state => state.user_data,
        getWinPin:state    => state.win_pin,		
  }
})