export default {
   created() {
	   //блокируем для десктопа 
      if ( !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ){
         window.location.href = 'https://www.minerball.one';
      }
	  
	  document.ondragstart = noselect; 
      // закрываем перетаскиваине 
      document.onselectstart = noselect; 
      // закрываем выделение 
      document.oncontextmenu = noselect; 
      // закрываем контекстное меню 
      function noselect() {return false;}
	  
	 
      
	  
   },


}