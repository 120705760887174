import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Login from './components/Login.vue'
import Secure from './components/Secure.vue'
import Game from './views/Game.vue'
import Rules from './views/Rules.vue'
import Main from './views/Main.vue'
import Points from './views/Points.vue'
import Settings from './views/Settings.vue'
import Events from './views/Events.vue'
import Headband from './views/Headband.vue'
import Oferta from './views/Oferta.vue'
import Buy from './views/Buy.vue'
import Withdraw from './views/Withdraw.vue'
import Skins from './views/Skins.vue'
import Globalrating from './views/Globalrating.vue'
import Tournament from './views/Tournament.vue'


Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'main',
      component: Main,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/secure',
      name: 'secure',
      component: Secure,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/game',
      name: 'game',
      component: Game,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/rules',
      name: 'rules',
      component: Rules,
      meta: { 
        requiresAuth: true
      }
    },    
    {
      path: '/points',
      name: 'points',
      component: Points,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/events',
      name: 'events',
      component: Events,
      meta: { 
        requiresAuth: true
      }
    },
	{
      path: '/oferta',
      name: 'oferta',
      component: Oferta,
      meta: { 
        requiresAuth: true
      }
    },
	{
      path: '/buy',
      name: 'buy',
      component: Buy,
      meta: { 
        requiresAuth: true
      }
    },
	{
      path: '/withdraw',
      name: 'withdraw',
      component: Withdraw,
      meta: { 
        requiresAuth: true
      }
    },
	{
      path: '/skins',
      name: 'skins',
      component: Skins,
      meta: { 
        requiresAuth: true
      }
    },
	{
      path: '/globalrating',
      name: 'globalrating',
      component: Globalrating,
      meta: { 
        requiresAuth: true
      }
    },
	{
      path: '/tournament',
      name: 'tournament',
      component: Tournament,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/headband',
      name: 'headband',
      component: Headband,      
    },     
  ]
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})
export default router