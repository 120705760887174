<template>
  <div class="content" id="headband" >
    
  </div>  
</template>
<script>
import axios from 'axios'

export default {
  data(){
      return {
        content: '',
        //доступы
		image: '',
		id: '',
        name: '', 
        token: '',
		email: '',
        //end
        //словарь
        dict: {},		
      }
  },
  methods: {
      isEmpty: function (obj) {
         for (let key in obj) {
            // если тело цикла начнет выполняться - значит в объекте есть свойства
            return false;
         }
         return true;
      },
	  
	  
  },
  
  created() {
       
      /* доступы */
          
      if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
      }
     
      if(localStorage.getItem('name') != null){
        this.name = localStorage.getItem('name')
      }
	 
	  if(localStorage.getItem('id') != null){
        this.id = localStorage.getItem('id')
      }
	 
	  if(localStorage.getItem('image') != null){
        this.image = localStorage.getItem('image')
      }
	  
	  if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
      }
	  
	  this.dict = this.$store.getters.getLang
	  if(this.isEmpty(this.dict)){ //если словарь пустой. запрашиваем
	        const data = JSON.stringify({'q' : 'get_lang'})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {
		        if (typeof response.data.lang !== 'undefined') {
		                                			  
				    /*записываем полученный словарь*/
				    this.$store.commit('setLang', response.data.lang)				  
				    this.dict = this.$store.getters.getLang                 			  
				  
				    /*end*/		  
               
		        }
		   
            })
            .catch(err => {
                console.log(err)
            })
	  }
	  /*end*/
      const data = JSON.stringify({'q' : 'auth_user', data: { 'nickname' : this.name }})  
      axios.post(this.$hostname+'user-cards', "data="+data)
        .then(response => {             
			
			if (typeof response.data.user.id !== 'undefined') {
			    this.$router.push('/')
			}
		   
        })
        .catch(err => {
           console.log(err)
        })
     
     
        
  },
              
} 

</script>
     
