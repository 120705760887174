<template>
  <div class="content">
     <div class="triangle_left" v-on:click="ToMenu()"></div><div class="item"><h4>{{dict.global_rating}}</h4></div>
	 <div id="external_content_for_rating" v-html="field_external_content"></div>
     
  </div>
</template>

<script>
import axios from 'axios'


export default {
  
  data(){
      return {
	     //доступы
		image: '',
		id: '',
        name: '', 
        token: '',
		email: '',
		count_win: '', 
        //end
		//словарь
        dict: {},
		field_external_content: '', //поле с внешним контентом
		
		
	  }
   
  },
  
  methods: {
      ToMenu: function () {
         this.$router.push('/events')
      },
	  
	  isEmpty: function (obj) {
         for (let key in obj) {
            // если тело цикла начнет выполняться - значит в объекте есть свойства
            return false;
         }
         return true;
      },
	  
	  
	  
   },	  
	  
   created() {
        
     /* доступы */
          
     if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
     }
     
     if(localStorage.getItem('name') != null){
        this.name = localStorage.getItem('name')
     }
	 
	 if(localStorage.getItem('id') != null){
        this.id = localStorage.getItem('id')
     }
	 
	 if(localStorage.getItem('image') != null){
        this.image = localStorage.getItem('image')
     }
	 
	 if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
		this.responsible_game = true
     }
	 
	 this.dict = this.$store.getters.getLang
	 if(this.isEmpty(this.dict)){ //если словарь пустой. запрашиваем
	        const data = JSON.stringify({'q' : 'get_lang', data: {'token' : this.token}})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {
		        if (typeof response.data.lang !== 'undefined') {
		                                			  
				    /*записываем полученный словарь*/
				    this.$store.commit('setLang', response.data.lang)				  
				    this.dict = this.$store.getters.getLang                 			  
				     
				    /*end*/		  
               
		        }
		   
            })
            .catch(err => {
                console.log(err)
            })
	 }
	 
	 const data = JSON.stringify({'q' : 'get_result_global_rating', data: { 'name' : this.name, 'id' : this.id, 'token' : this.token }})  
     axios.post(this.$hostname+'user-cards', "data="+data)
        .then(response => {
		    if (typeof response.data.status_game !== 'undefined' && response.data.status_game == 0) {
			    this.$router.push('/headband')
			}
            this.field_external_content = response.data.global_rating 
		   
        })
        .catch(err => {
           console.log(err)
        })
  
  },
  
  watch: {
  
  
  },
  
  
}

</script>  