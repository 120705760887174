<template>
  <div class="content">
    <div class="triangle_left" v-on:click="ToMenu()"></div><div class="item"><h4>{{dict.buy}}</h4></div>
	
	<div id=buy>
	    <p v-html="dict.instrukt1"></p>
	    <p v-html="dict.instrukt2"></p>
		<table role="table" aria-busy="false" aria-colcount="2" class="table b-table table-striped table-hover" id="__BVID__1044">
           <thead role="rowgroup" class="">
              <tr role="row" class="">
                 <th role="columnheader" scope="col" aria-colindex="1" class="">
                     <div>ton</div>
				 </th>
                 <th role="columnheader" scope="col" aria-colindex="2" class="">
                     <div>coins</div>
				 </th>
              </tr>
           </thead>
           <tbody role="rowgroup">
              <tr role="row" class="">
                 <td aria-colindex="1" role="cell" class="">1</td>
                 <td aria-colindex="2" role="cell" class="">{{exchange}}</td>
              </tr>
           </tbody>
        </table>
        <p v-html="dict.instrukt3"></p>
        <p v-html="dict.instrukt4+' '+dict.instrukt5"></p>
        <p v-html="dict.instrukt6"></p>
        <p>{{max_amount_exchange}}</p>
		<div id="sum_ton"><input  v-model="sum_ton" type="number" placeholder="ton" min="0" max="100"/></div>
		<p v-if="sum_ton" id="sumton"><a v-bind:href="url" target="_blank">buy {{sum_ton}} 	   <svg width="60" height="40" viewBox="0 0 100 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M49.078 27.714V16.276h-4.402v-3.065h12.61v3.065h-4.402v11.438h-3.806zm8.572-7.262c0-1.509.295-2.819.885-3.93a6.277 6.277 0 0 1 2.53-2.612c1.097-.617 2.393-.926 3.888-.926 1.508 0 2.811.309 3.909.926a6.284 6.284 0 0 1 2.53 2.613c.603 1.11.905 2.42.905 3.929 0 1.495-.302 2.804-.905 3.929a6.416 6.416 0 0 1-2.53 2.633c-1.097.617-2.4.926-3.909.926-1.495 0-2.791-.309-3.888-.926a6.582 6.582 0 0 1-2.53-2.633c-.59-1.125-.885-2.434-.885-3.93zm3.97 0c0 .905.124 1.68.371 2.324.247.645.61 1.139 1.09 1.482.494.342 1.117.514 1.872.514 1.125 0 1.968-.377 2.53-1.132.562-.768.844-1.83.844-3.188 0-.905-.123-1.68-.37-2.325-.247-.644-.624-1.131-1.132-1.46-.493-.343-1.118-.515-1.872-.515-1.125 0-1.961.377-2.51 1.132-.548.74-.822 1.796-.822 3.168zm12.954 7.262V13.21h2.798l7.344 9.113h-.597v-9.113h3.518v14.503H84.84L77.537 18.6h.596v9.113h-3.559z" fill="currentColor"></path><path d="M18 38c9.941 0 18-8.059 18-18S27.941 2 18 2 0 10.059 0 20s8.059 18 18 18z" fill="#08C"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M12.992 13.895h10.096c.357 0 .714.052 1.087.226.447.208.684.537.85.78a2.265 2.265 0 0 1 .332 1.185c0 .384-.09.802-.295 1.166l-.006.01-6.379 10.957a.783.783 0 0 1-1.355-.005L11.06 17.28l-.006-.01c-.143-.235-.364-.6-.403-1.072a2.136 2.136 0 0 1 1.219-2.105c.423-.198.851-.2 1.121-.2zm4.226 1.565h-4.226c-.277 0-.384.017-.458.052a.56.56 0 0 0-.322.56c.005.066.032.142.192.406a.913.913 0 0 1 .01.016l4.804 8.39V15.46zm1.566 0v9.465l4.915-8.444a.856.856 0 0 0 .093-.395.696.696 0 0 0-.08-.334 1.264 1.264 0 0 0-.125-.16.23.23 0 0 0-.073-.053.936.936 0 0 0-.426-.08h-4.304z" fill="#fff"></path></svg>
		</a></p>
        <p v-html="dict.instrukt8"></p>
       	<p v-html="dict.instrukt9"></p>
        <p v-html="dict.instrukt10"></p>		
	</div>
  </div>  
</template>
<script>
import axios from 'axios'

export default {
  data(){
      return {
	    //доступы
		image: '',
		id: '',
        name: '', 
        token: '',
		email: '',
        //end
        field_external_content: '', //поле с внешнийм контентом	
        //словарь
        dict: {},
		status_accept: 'not_accepted',
		exchange: null,
		max_amount_exchange: null,
		sum_ton: false,
		url: '#',
		
      }
  },
  methods: {
      ToMenu: function () {
         this.$router.push('/points')
      },
	  
	  isEmpty: function (obj) {
         for (let key in obj) {
            // если тело цикла начнет выполняться - значит в объекте есть свойства
            return false;
         }
         return true;
      },
	  
	  CorrectSum: function () {
	     if(this.sum_ton > 100){
		    this.sum_ton = 100
		 }
		 this.url = 'https://t.me/MinerBallBot?start=' + this.id + 'summinerballton' + this.sum_ton
	  },
	  
	  GetAccept: function(){
	     if(this.status_accept == 'accepted'){
		     const data = JSON.stringify({'q' : 'set_accepted', data: { 'name' : this.name, 'id' : this.id, 'token' : this.token, 'status_accept' : this.status_accept }})  
     axios.post(this.$hostname+'user-cards', "data="+data)
             .then(response => {
		        if (typeof response.data.status_accept !== 'undefined' &&       response.data.status_accept == 'set') {
			        this.$store.commit('setAccept', 'accepted')
			    }                
		   
             })
             .catch(err => {
                console.log(err)
             })
		 }
	  },
	  
  },
  created() {
        
     /* доступы */
          
     if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
     }
     
     if(localStorage.getItem('name') != null){
        this.name = localStorage.getItem('name')
     }
	 
	 if(localStorage.getItem('id') != null){
        this.id = localStorage.getItem('id')
     }
	 
	 if(localStorage.getItem('image') != null){
        this.image = localStorage.getItem('image')
     }
	 
	 if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
		this.responsible_game = true
     }
	 
	 if(this.$store.getters.getAccept != null && this.$store.getters.getAccept != 'accepted'){
        this.status_accept = 'not_accepted'
		this.$router.push('/oferta')
     }
	 
	 this.dict = this.$store.getters.getLang
	 if(this.isEmpty(this.dict)){ //если словарь пустой. запрашиваем
	        const data = JSON.stringify({'q' : 'get_lang', data: {'token' : this.token}})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {
		        if (typeof response.data.lang !== 'undefined') {
		                                			  
				    /*записываем полученный словарь*/
				    this.$store.commit('setLang', response.data.lang)				  
				    this.dict = this.$store.getters.getLang                 			  
				  
				    /*end*/		  
               
		        }
		   
            })
            .catch(err => {
                console.log(err)
            })
	 }
	 /*end*/
	 
	 const data = JSON.stringify({'q' : 'get_oferta', data: { 'name' : this.name, 'id' : this.id, 'token' : this.token }})  
     axios.post(this.$hostname+'user-cards', "data="+data)
        .then(response => {
		    if (typeof response.data.status_game !== 'undefined' && response.data.status_game == 0) {
			    this.$router.push('/headband')
			}
            this.field_external_content = response.data.oferta 
		   
        })
        .catch(err => {
           console.log(err)
        })
		
	  const dt = JSON.stringify({'q' : 'get_settings', data: {'token' : this.token}})  
     axios.post(this.$hostname+'user-cards', "data="+dt)
        .then(response => {
            this.exchange = response.data.exchange
			this.max_amount_exchange = this.dict.instrukt7 + ' : ' + response.data.max_amount_exchange + ' ton'
			
        })
        .catch(err => {
           console.log(err)
        })	 	
     
     
        
  },
  watch: {
    // эта функция запускается при любом status_accept
    status_accept: function () {
       this.GetAccept()
    },
	
	sum_ton: function () {
	   this.CorrectSum()
	},
    
  },
              
} 

</script>     
