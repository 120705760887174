<template>
   
   <div id="content_game" class="content">
      <div class="triangle_left" v-on:click="ToMenu()"></div><div class="item"><h4>{{dict.game}}</h4></div>
      <div id="responsible_game_menu" v-if="responsible_game" v-on:click="responsibleGame()">
	  <svg id="coins" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 80C512 98.01 497.7 114.6 473.6 128C444.5 144.1 401.2 155.5 351.3 158.9C347.7 157.2 343.9 155.5 340.1 153.9C300.6 137.4 248.2 128 192 128C183.7 128 175.6 128.2 167.5 128.6L166.4 128C142.3 114.6 128 98.01 128 80C128 35.82 213.1 0 320 0C426 0 512 35.82 512 80V80zM160.7 161.1C170.9 160.4 181.3 160 192 160C254.2 160 309.4 172.3 344.5 191.4C369.3 204.9 384 221.7 384 240C384 243.1 383.3 247.9 381.9 251.7C377.3 264.9 364.1 277 346.9 287.3C346.9 287.3 346.9 287.3 346.9 287.3C346.8 287.3 346.6 287.4 346.5 287.5L346.5 287.5C346.2 287.7 345.9 287.8 345.6 288C310.6 307.4 254.8 320 192 320C132.4 320 79.06 308.7 43.84 290.9C41.97 289.9 40.15 288.1 38.39 288C14.28 274.6 0 258 0 240C0 205.2 53.43 175.5 128 164.6C138.5 163 149.4 161.8 160.7 161.1L160.7 161.1zM391.9 186.6C420.2 182.2 446.1 175.2 468.1 166.1C484.4 159.3 499.5 150.9 512 140.6V176C512 195.3 495.5 213.1 468.2 226.9C453.5 234.3 435.8 240.5 415.8 245.3C415.9 243.6 416 241.8 416 240C416 218.1 405.4 200.1 391.9 186.6V186.6zM384 336C384 354 369.7 370.6 345.6 384C343.8 384.1 342 385.9 340.2 386.9C304.9 404.7 251.6 416 192 416C129.2 416 73.42 403.4 38.39 384C14.28 370.6 .0003 354 .0003 336V300.6C12.45 310.9 27.62 319.3 43.93 326.1C83.44 342.6 135.8 352 192 352C248.2 352 300.6 342.6 340.1 326.1C347.9 322.9 355.4 319.2 362.5 315.2C368.6 311.8 374.3 308 379.7 304C381.2 302.9 382.6 301.7 384 300.6L384 336zM416 278.1C434.1 273.1 452.5 268.6 468.1 262.1C484.4 255.3 499.5 246.9 512 236.6V272C512 282.5 507 293 497.1 302.9C480.8 319.2 452.1 332.6 415.8 341.3C415.9 339.6 416 337.8 416 336V278.1zM192 448C248.2 448 300.6 438.6 340.1 422.1C356.4 415.3 371.5 406.9 384 396.6V432C384 476.2 298 512 192 512C85.96 512 .0003 476.2 .0003 432V396.6C12.45 406.9 27.62 415.3 43.93 422.1C83.44 438.6 135.8 448 192 448z" style="width: 40px;height: 40px;"/></svg>
	  </div>
	  
	  <div id="game">
         <div v-if="preview" id="choice">
	        <h5>{{dict.you_have}}&nbsp;{{ points }}</h5>
            <h5>{{dict.your_bet}}</h5>
            <div class="bets">
			    <div v-if="val <= points" class="bet" :id="key" v-for="(val, key) in bets" v-on:click="ChoiceBet(key)">{{val}}</div> 
                
			</div>
	     </div>
		 <div id="players" v-if="play">
		   <div v-if="game_message" id="exit_game" v-on:click="exitGame()">{{dict.leave_the_game}}</div>
		   <div>
		      <div id="stars_left" class="stars" v-if="stars_box_left > 0">
			      <div v-for="star in stars_box_left">⭐</div>
			  </div>
		      <img id="your" alt="" class="round" :src="your_image">			  
		      <span id="cashbox">{{  cashbox }}</span>			  
		      <img id="another_player" alt="" class="round" :src="image_another_player">
			  <div id="stars_right" class="stars" v-if="stars_box_right > 0">
			      <div v-for="star in stars_box_right">⭐</div>
			  </div>
		   </div>
		   <div>
		      <div class="name_players first_player">
			     <div><span id="first_player">{{name}}</span></div>				 
			     <div><span id="left_mid_result">{{ your_mid_result }}</span></div>
			  </div>
			  <div v-if="game_message" id="message">
			      <div><b>{{goes}}</b></div>
			      <div><b>{{message}}</b></div>
				  <div style="font-size:1.3rem"><b>{{ countDown }}</b></div>
			  </div>
			  <div class="name_players second_player">
			      <div><span id="second_player">{{name_another_player}}</span></div>
			      <div><span id="right_mid_result">{{ another_mid_result }}</span></div>
			  </div>
		   </div>		   
		 </div>
		 <div v-if="fin_message" id="fin_message" v-html="message"></div>
		 <div v-if="fin_message" id="again_game" v-on:click="againGame()">{{dict.new_game
}}</div>
		 <div id="field" v-if="play">
           <div v-bind:class="classObject" :id="k" v-for="(v, k) in blocks" >
              <div class="front" v-on:click="Choice(k)">    
              </div>
              <div class="back">
                 <h2 :id="v"></h2>
              </div>
           </div>
         </div>		   
         <div v-if="showLoader" >
		    <div id="loader"><h4>{{dict.looking_for_a_game}}</h4></div>
		    <div class="load">
			    <hr/><hr/><hr/><hr/>
            </div>
         </div>		 
	  </div>
	  
      <b-alert
         :show="dismissCountDown"
         dismissible
         variant="warning"
         @dismissed="dismissCountDown=0"
         @dismiss-count-down="countDownChanged"
      >
	     {{ modal_message }}
      <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress>
      </b-alert>
	  
	  <div v-if="it_gift" id="daylik" v-on:click="getGift()">
	      <svg id="gifts" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path d="M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z" style="width: 40px;height: 40px;"/></svg>
	  </div>
	  
	 <!-- <div  v-html>
	  
	  </div> -->
	  
	  <div id="banner"><a :href="link_banner" target="_blank" v-on:click="$metrika.reachGoal('click_bottom_banner')"><img v-if="image_banner.length > 20" :src="image_banner" /></a></div>
   </div>
      
</template>

<script>
import axios from 'axios'

export default {
  	
  data(){
      return {
	    classObject: {
		   click: true,
		   flip: false,
		},
	    showLoader: false, 
	    bets: {
		    '100' : 100,
			'200' : 200,
			'300' : 300,
			'500' : 500,
			
		},
        blocks: {
            '1' : 'v1',
            '2' : 'v2',
            '3' : 'v3',
			'4' : 'v4',
			'5' : 'v5',
			'6' : 'v6',
			'7' : 'v7',
			'8' : 'v8',
			'9' : 'v9',
         },
        play: false,
        preview: true,
		points: 0,
        //доступы
		image: '',
		id: '',
        name: '', 
        token: '',
		email: '',
        //end
		//reclama
		image_banner: '',
		link_banner: '#',
		//end
        id_game: '',
        ids_players: [],
        name_another_player: '',
        image_another_player: '',
		id_another_player: '',
        your_image: '',
        cashbox: '',
        message: '',
		goes: '',
        countDown: 5,
        players: '',
        queue: '', //очередь игроков с id игрока чей ход
        potential: true, //возможность делать ход
		potential_bet: true, //возможность делать ставку
        last_event: -1, //id последнего события	
        game_message: true,
        fin_message: false,
        responsible_game: false,
        real_game: false, //флаг запуска игры на coins
		dismissSecs: 5,
        dismissCountDown: 0,  //показ модального окна       
        modal_message: '', //сообщение модального окна	
        //словарь
        dict: {},
        //ранги игроков
		stars_box_left: 0,
        stars_box_right: 0,
		//промежуточные результаты игроков
		your_mid_result: '',
		another_mid_result: '',
		color_gift: 0,
		it_gift: true, //ситуативное включение дейлика
		gifts: false, //глобальное включение дейлика
      }
  },
  methods: {
      ToMenu: function () {
	     if(!this.potential_bet){
            return false
         }
         this.$router.push('/')
      },
	  
	  //выбор ставки запускает создание игры
	  ChoiceBet: function (bet) {
	    if(!this.potential_bet){
            return false
        }
        this.potential_bet = false //запрет повторного выбора ставки
		
		this.it_gift = false
        this.preview = false		
        this.showLoader = true 
		this.points = this.points - bet
		 
		let data = JSON.stringify({'q' : 'create_game', 'game' : {'bet' : bet, 'real_game' : this.real_game}, 'user' : {'id' : this.id, 'token' : this.token}})  
        axios.post(this.$hostname+'game-cards', "data="+data)
        .then(response => {           
            if(response.data.id_game){
			    this.potential_bet = true
                this.showLoader = false
			    
                this.play = true             
            
			    localStorage.setItem('id_game', response.data.id_game)
				this.id_game = response.data.id_game
				if(response.data.players){
			        var obj = response.data.players
					this.players = obj
                    for(var key of Object.keys(obj)){				    
                        if(key == this.id){					    
					        this.your_image = obj[key].image
							this.name = obj[key].name
							this.stars_box_left = obj[key].rang > 6 ? 6 : obj[key].rang ;
					    }
					    if(key != this.id){					    
					        this.image_another_player = obj[key].image
						    this.name_another_player = obj[key].name
						    this.stars_box_right = obj[key].rang
					    }
                    }
					this.queue = response.data.queue[0]
					this.goes = this.dict.goes
				    this.message = this.players[this.queue].name
					this.countDown = response.data.countdown
					this.countDownTimer()
			    }				
			    
			    this.cashbox = response.data.cashbox
		    }
            if(typeof response.data.result !== 'undefined'){
			    this.potential_bet = true
                this.showLoader = false
				this.modal_message = response.data.result
                this.dismissCountDown = this.dismissSecs				
            }				
        })
        .catch(err => {
           console.log(err)
        })
      },
	  
	  //обрабатываем ход игрока
	  Choice: function (k) {
         //проверяем может ли игрок делать ход
		 if(this.id != this.queue){
		    return false
		 }
         if(!this.potential){
            return false
         }
         this.potential = false //запрет повторного нажатия		 
		 //end	 
		 let data = JSON.stringify({'q' : 'made_choice', 'game' : {'id_game' : this.id_game, 'queue' : this.queue, 'position' : k, 'real_game' : this.real_game}, 'user' : {'id' : this.id, 'name' : this.name, 'token' : this.token}})  
         axios.post(this.$hostname+'game-cards', "data="+data)
         .then(response => { 
               if(response.data.value != false && typeof response.data.value != "undefined"){
			       let id_block = 'v' + k
			       let element = document.getElementById(id_block) //получаем обратную сторону карточки
				   
                   element.innerHTML = response.data.value //подставляем в карточку значение			   
			       let el = document.getElementById(k) //получаем всю карточку для анимации переворота
		           el.classList.add('flip')	
               
               //this.countDown = 0			   
               //this.changeQueue()				 
			   }
         })
         .catch(err => {
           console.log(err)
         })		 
		 
		 
      },	 
	  
	  countDownTimer() {
	     if(this.id_game && this.play){           
             setTimeout(() => {                 
                 this.countDownTimer()
				 this.getEvents()
             }, 500)           
		 }  
      },
	  
	  //получаем события 
	  getEvents(){
	     let data = JSON.stringify({'q' : 'getEvents', 'game' : {'id_game' : this.id_game, 'last_event' : this.last_event, 'real_game' : this.real_game}, 'user' : {'id' : this.id, 'token' : this.token}})  
         axios.post(this.$hostname+'game-cards', "data="+data)
         .then(response => {
		     this.last_event = response.data.last_event //обновляем индекс last_event
			 let events = response.data.events
		     
		     if(events.length > 0){
			    				
				//перебираем события
				for (let i = 0; i < events.length; ++i) {
				    //console.log(events[i])
                    switch (events[i].event) {
                        case 'exit_game':
                            this.game_message = false;
							this.fin_message = true;
							this.message = events[i].message;
							localStorage.setItem('id_game', null);
				            this.id_game = '';
				            this.ids_players = [];
                            this.name_another_player = '';
                            this.image_another_player = '';
		                    this.id_another_player = '';
                            this.cashbox = '';
                            this.message = events[i].message;
                            this.countDown = 5;
                            this.players = '';
                            this.queue = ''; 
                            this.potential = true; 
                            this.last_event = 0; 
				 
				            //скрываем игровое поле. 
				            this.play = false
                        break;
                        case 'stop_game':
                            this.game_message = false;
							this.fin_message = true;
							this.message = events[i].message;
							localStorage.setItem('id_game', null);
				            this.id_game = '';
				            this.ids_players = [];
                            this.name_another_player = '';
                            this.image_another_player = '';
		                    this.id_another_player = '';
                            this.cashbox = '';
                            this.countDown = 5;
                            this.players = '';
                            this.queue = '';
                            this.potential = true;
                            this.last_event = 0;
                            this.your_mid_result = '';
		                    this.another_mid_result = '';							
				 
				            //скрываем игровое поле. 
				            this.play = false;
							this.points = events[i].value; //обновляем баллы игрока
							
							
                        break;
                        case 'made_move':
						    if(typeof events[i].queue != "undefined"){
						        let id_block = 'v' + events[i].message;
			                    let element = document.getElementById(id_block); //получаем обратную сторону карточки
                                element.innerHTML = events[i].value; //подставляем в карточку значение			   
			                    let el = document.getElementById(events[i].message); //получаем всю карточку для анимации переворота
		                        el.classList.add('flip');	//переворачиваем карточку
							
							
							    this.queue = events[i].queue
			                    this.goes = this.dict.goes
			                    this.message = this.players[this.queue].name
		                        this.countDown = events[i].countdown			 
			                    if(this.queue == this.id){ //если очередь совпадает - разблокируем возможность хода
			                        this.potential = true
			                    }
								
								//если есть результаты игроков. то выводим их под иконками в ходе игры
								if(typeof response.data.results != "undefined"){
								    var obj = response.data.results
					                
                                    for(var key of Object.keys(obj)){				    
                                        if(key == this.id){					    
					                        this.your_mid_result = obj[key]				                
					                    }
					                    if(key != this.id){					    
					                        this.another_mid_result = obj[key]	                    
					                    }
                                    }
								}
                            }
                            
		                    							
                        break;
						case 'wait':
						    this.queue = events[i].queue
							this.goes = this.dict.goes
							this.message = this.players[this.queue].name
							this.countDown = events[i].countdown
							console.log(this.countDown)
							if(this.queue == this.id){ //если очередь совпадает - разблокируем возможность хода
			                    this.potential = true
			                }
							
						break;	
                        default:
                            return false;
                    }
                }				
			 
			 }
		     
         })
         
         .catch(err => {
            console.log(err)
         })	
	  },
	  
	  changeQueue() {
	     let data = JSON.stringify({'q' : 'changeQueue', 'game' : {'id_game' : this.id_game, 'queue' : this.queue, 'real_game' : this.real_game}, 'user' : {'id' : this.id, 'token' : this.token}})  
         axios.post(this.$hostname+'game-cards', "data="+data)
         .then(response => {
		     this.queue = response.data.queue[0]
			 this.goes = this.dict.goes
			 this.message = this.players[this.queue].name
		     this.countDown = response.data.countdown			 
			 if(this.queue == this.id){ //если очередь совпадает - разблокируем возможность хода
			    this.potential = true
			 }
		     
         })
         
         .catch(err => {
            console.log(err)
         })		 
	  },
	  
	  //срабатывает когда игрок покидает игру
	  exitGame() {
	     let data = JSON.stringify({'q' : 'exitGame', 'game' : {'id_game' : this.id_game, 'real_game' : this.real_game}, 'user' : {'id' : this.id, 'name' : this.name, 'token' : this.token}})  
         axios.post(this.$hostname+'game-cards', "data="+data)
         .then(response => {
		     if(response.data.result == 'ok'){ //обнуляем все данные предыдущей игры
                 localStorage.setItem('id_game', null)
				 this.id_game = ''
				 this.ids_players = []
                 this.name_another_player = ''
                 this.image_another_player = ''
		         this.id_another_player = ''
                 this.cashbox = ''
                 this.message = ''
                 this.countDown = 0
                 this.players = ''
                 this.queue = '' 
                 this.potential = true 
                 this.last_event = 0 
				 
				 //скрываем игровое поле. показываем ставки
				 this.play = false
		         this.preview = true
				 this.it_gift = true
				 
				 //показ подарка	 
	             setTimeout(() => { this.cl() }, 150)
				 
             }		     
         })
         
         .catch(err => {
            console.log(err)
         })
         		 
	  },
	  
	  //обработка события нажатия на кнопку - новая игра. показываем окно со ставками
	  againGame() {
	     const data = JSON.stringify({'q' : 'get_points', data: { 'name' : this.name, 'id' : this.id, 'token' : this.token, 'real_game' : this.real_game, 'email' : this.email}})  
         axios.post(this.$hostname+'user-cards', "data="+data)
         .then(response => {
           if(!this.real_game){
              this.points = response.data.points //для простой игры
		   }else{
              this.points = response.data.coins //для ответственной игры
           }			   
		   
         })
         .catch(err => {
           console.log(err)
         })
	     this.preview = true
		 this.fin_message = false
		 this.game_message = true
		 
		 if(this.gifts != false){
		     this.it_gift = true
		     //показ подарка	 
	         setTimeout(() => { this.cl() }, 150)
		 }	 
		 
		 //this.message = ''
		 
		 
	  },
	  
	  responsibleGame() {
	     if(!this.potential_bet){
            return false
         }
	     
		 if(!this.real_game){ 
	        var elem = document.getElementById('choice')
		    elem.style.cssText = `background-color: #26A69A !important;`;
	        var elems = document.getElementById('choice').children
		    for(var i = 0; i < elems.length; i++) {
               elems[i].style.fontWeight = 900;
			   elems[i].style.cssText = "color:#ffffff !important";
            }
         
		    const data = JSON.stringify({'q' : 'get_coins', data: { 'name' : this.name, 'id' : this.id, 'token' : this.token, 'email' : this.email}})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {
		        elems[0].textContent = this.dict.you_have + " " + response.data.coins + " coins"
				this.points = response.data.coins
			    this.real_game = true
		    })
            .catch(err => {
              console.log(err)
            })
		 }else{
			 this.real_game = false
			 this.$router.go(this.$router.currentRoute) //перезагружаем роут
		 }	 
         		 
	  
	  },
	  
	  countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
	  
	  isEmpty: function (obj) {
         for (let key in obj) {
            // если тело цикла начнет выполняться - значит в объекте есть свойства
            return false;
         }
         return true;
      },
	  
	  //мерцание подарка
	  cl(){
	     
	     let dt=new Array("#ADFF2F", "#00FF00", "#2E8B57", "#9ACD32", "#66CDAA", "#00FFFF", "#4682B4", "#7B68EE", "#000080", "#8B008B", "#BA55D3", "#FF00FF", "#FFFF00", "#FF6347", "#FF0000", "#FA8072")
		 
		// let i = this.getRandomIntInclusive(0, 15)
		 
		 if (this.color_gift>dt.length) this.color_gift=0; 
	     document.getElementById("gifts").style.fill=dt[this.color_gift++]; 
         setTimeout(() => {                 
                 this.cl()				 
             }, 150)         
            
	  },
	  
	  getRandomIntInclusive(min, max) {
         min = Math.ceil(min);
         max = Math.floor(max);
         return Math.floor(Math.random() * (max - min + 1)) + min;
      },
	  
	  //распаковываем подарочек
	  getGift(){
	     const data = JSON.stringify({'q' : 'get_gift', data: { 'name' : this.name, 'id' : this.id, 'token' : this.token, 'email' : this.email}})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {
		        if(typeof response.data.daylik != "undefined" && response.data.daylik == 'day_points'){
				    this.points = this.points + response.data.entity
					document.getElementById('daylik').innerHTML = response.data.text
					this.gifts = false
				}
		    })
            .catch(err => {
              console.log(err)
            })
	  },
  },
  
  created() {
      
     /* доступы */
          
     if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
     }
     
     if(localStorage.getItem('name') != null){
        this.name = localStorage.getItem('name')
     }
	 
	 if(localStorage.getItem('id') != null){
        this.id = localStorage.getItem('id')
     }
	 
	 if(localStorage.getItem('image') != null){
        this.image = localStorage.getItem('image')
     }
	 
	 if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
		this.responsible_game = true
     }
	 
	 this.dict = this.$store.getters.getLang
	 if(this.isEmpty(this.dict)){ //если словарь пустой. запрашиваем
	        const data = JSON.stringify({'q' : 'get_lang', data: {'token' : this.token}})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {
		        if (typeof response.data.lang !== 'undefined') {
		                                			  
				    /*записываем полученный словарь*/
				    this.$store.commit('setLang', response.data.lang)				  
				    this.dict = this.$store.getters.getLang                 			  
				  
				    /*end*/		  
               
		        }
		   
            })
            .catch(err => {
                console.log(err)
            })
	 }
	 /*end*/
	 
	 //если произошла перезагрузка страницы. проверяем игру
	 
	 if(localStorage.getItem('id_game') && localStorage.getItem('id_game') != 'null'){
	    
	    this.id_game = localStorage.getItem('id_game')	
		
		const data = JSON.stringify({'q' : 'get_game', game: { 'id_game' : this.id_game, 'real_game' : this.real_game, 'email' : this.email }, user : { 'id' : this.id, 'token' : this.token }})  
        axios.post(this.$hostname+'game-cards', "data="+data)
		
		.then(response => {
		    if(response.data.players){
			    var obj = response.data.players
				this.players = obj
                for(var key of Object.keys(obj)){				    
                    if(key == this.id){					    
					    this.your_image = obj[key].image
						this.name = obj[key].name
					}
					if(key != this.id){					    
					    this.image_another_player = obj[key].image
						this.name_another_player = obj[key].name
						this.id_another_player = key
					}
                }
				this.queue = response.data.queue[0]
				this.message = this.dict.goes + ' ' + this.players[this.queue].name
				this.countDown = response.data.countdown
				this.countDownTimer()
			} 
            			
			this.cashbox = response.data.cashbox
        })
        .catch(err => {
           console.log(err)
        })
		
		this.showLoader = false
	    this.preview = false
        this.play = true
		
		 
       /* this.$nextTick(function () { //в этой функции вызывается обработка потому, что иначе не успевает отрендериться страница и свойства запрашиваются до появления объекта    
            let el = document.getElementById("3")
		    el.classList.add('flip')
        })*/
		
	 }else{ //если нет id_game показываем выбор ставок
	    this.play = false
		this.preview = true
	 }
             
      
     const data = JSON.stringify({'q' : 'get_points', data: { 'name' : this.name, 'id' : this.id, 'token' : this.token, 'real_game' : this.real_game, 'email' : this.email}})  
     axios.post(this.$hostname+'user-cards', "data="+data)
        .then(response => {
		   if (typeof response.data.status_game !== 'undefined' && response.data.status_game == 0) {
			    this.$router.push('/headband')
		   }
		   
		   if (typeof response.data.status_rg_game !== 'undefined' && response.data.status_rg_game == 0) {
			    this.responsible_game = false
		   }
			
           if(response.data != false){
              this.points = response.data.points
			  if (typeof response.data.email !== 'undefined' && this.email == '') {
			    localStorage.setItem('email', response.data.email)
				this.email = response.data.email
				if (typeof response.data.status_rg_game !== 'undefined' && response.data.status_rg_game == 0) {
			       this.responsible_game = false
		        }else{
				   this.responsible_game = true
				}  
		      }
		   }else{
		      this.token = ''
              localStorage.setItem('token', '')
           }
           
		   if (typeof response.data.status_daylik !== 'undefined' && response.data.status_daylik == true){
		       //показ подарка
               this.it_gift = true
               this.gifts = true //включаем подарки			   
	           setTimeout(() => { this.cl() }, 150)
           }else{
               this.it_gift = false
			   this.gifts = false
           }		   
		   
        })
        .catch(err => {
           console.log(err)
        })
		
	 const reclama = JSON.stringify({'q' : 'get_banner'})  
     axios.post(this.$hostname+'user-cards', "data="+reclama)
     .then(resp => {
		 if(resp.data != false){
             this.image_banner = resp.data.banner
			 this.link_banner = resp.data.link_banner
         }		 
     })         
     
     
	       
        
  },
  
  watch: {
    play: function () {
      
    },
  }	
              
}


</script> 
<style>
@media (orientation: landscape) {
  #content_game {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

</style>    

