<template>
  <div>
    <div id="main_item"><h4>minerball</h4></div>
	<div class="main_menu"><button class="main_menu_btn" v-on:click="ToGame()">{{dict.game}}</button></div>
	<div class="main_menu"><button class="main_menu_btn" v-on:click="ToPoints()">{{dict.points}}</button></div>
	<div class="main_menu"><button class="main_menu_btn" v-on:click="ToEvents()">{{dict.events}}</button></div>
	<div class="main_menu"><button class="main_menu_btn" v-on:click="ToSettings()">{{dict.settings}}</button></div>
	<div class="main_menu"><button class="main_menu_btn" v-on:click="ToRules()">{{dict.rules}}</button></div>	
  </div>
</template>
<script>
import axios from 'axios'

export default {
  data(){
      return {
        nickname : "", 
        //словарь
        dict: {},		
      }
  },
  methods: {
      ToGame: function () {
         this.$router.push('/game')
      },
	  ToPoints: function () {
         this.$router.push('/points')
      },
	  ToEvents: function () {
         this.$router.push('/events')
      },
	  ToSettings: function () {
         this.$router.push('/settings')
      },
	  ToRules: function () {
         this.$router.push('/rules')
      },
	  
	  isEmpty: function (obj) {
         for (let key in obj) {
            // если тело цикла начнет выполняться - значит в объекте есть свойства
            return false;
         }
         return true;
      },
	  
  },
  
  created(){
       
      this.dict = this.$store.getters.getLang
      if(this.isEmpty(this.dict)){ //если словарь пустой. запрашиваем
	        const data = JSON.stringify({'q' : 'get_lang'})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {
		        if (typeof response.data.lang !== 'undefined') {
		                                			  
				    /*записываем полученный словарь*/
				    this.$store.commit('setLang', response.data.lang)				  
				    this.dict = this.$store.getters.getLang                 			  
				  
				    /*end*/		  
               
		        }
		   
            })
            .catch(err => {
                console.log(err)
            })
	  }
       
      axios.post(this.$hostname+'user-cards')
        .then(response => {
             
			if (typeof response.data.status_game !== 'undefined' && response.data.status_game == 0) {
			    this.$router.push('/headband')
			}
		   
        })
        .catch(err => {
           console.log(err)
        })
  
  },
              
}      
  




</script>

<style>

</style>