<template>
  <div class="content">
    <div class="triangle_left" v-on:click="ToMenu()"></div><div class="item"><h4>{{dict.settings}}</h4></div>
	<div id=content>
	    <img alt="" class="round" :src="image" v-on:click="ToSkins()">
		<div v-if="stars_box > 0">
		   <div v-for="star in stars_box" class="stars_box_items">⭐</div>
		   <div>{{rank}}</div>
	    </div>
	    <div id="notations">
		   <p>{{dict.link_your_account_to_email}}</p>
		   <p>{{dict.this_will_help_you_recover_your_account}}</p>
		   <p>{{dict.this_will_enable_you_to_play_a_responsible_game}}</p>		   
		</div>
        <div id="block_email">
           <input v-if="look_email == 1" v-model="new_email" type="email" placeholder="email" />
           <label v-if="look_email == 0"><b>email:</b> {{ email }}</label>		   
		   <label v-if="look_email == 1" id="send_email" v-on:click="sendEmail">{{dict.send}}</label>
		   <br/>
		   <label v-model="message" id="mes" style="color:blue">{{message}}</label>
		   <input v-show="win_pin != 0" type="text" v-model="pin" v-on:input="sendPin"/>
		   
		</div>
		
	</div>
	
	<div id="lang">
	    <treeselect
		    v-model="valueCountry"
            :options="options"
            :disable-branch-nodes="true"
            :show-count="true"
            :placeholder="dict.Where_are_you_from"
        />
	    
	</div>
  </div>  
</template>
<script>
import axios from 'axios'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'


export default {
  components: { Treeselect },

  data(){
      return {
        nickname : "",
        new_email: '',
		your_image: '',
        //доступы
		image: 'https://myhap.ru/icons/ava.png',
		id: '',
        name: '', 
        token: '',
		email: '',
		look_email: 1,
        //end
        message: '',
		mes: '',
        win_pin: 0,	
        pin: null,
		stars_box: 0,
		rank: '',
        //словарь
        dict: {},
		//анкета игрока
		user_data: {},
		//страны
		valueCountry: null,
        options: [
  {
    id: 'africa',
    label: 'Africa',
    children: [
      {
        id: 'DZ',
        label: 'Algeria 🇩🇿',
      },
      {
        id: 'AO',
        label: 'Angola 🇦🇴',
      },
      {
        id: 'BJ',
        label: 'Benin 🇧🇯',
      },
      {
        id: 'BW',
        label: 'Botswana 🇧🇼',
      },
      {
        id: 'BF',
        label: 'Burkina 🇧🇫',
      },
      {
        id: 'BI',
        label: 'Burundi 🇧🇮',
      },
      {
        id: 'CM',
        label: 'Cameroon 🇨🇲',
      },
      {
        id: 'CV',
        label: 'Cape Verde 🇨🇻',
      },
      {
        id: 'CF',
        label: 'Central African Republic 🇨🇫',
      },
      {
        id: 'TD',
        label: 'Chad 🇹🇩',
      },
      {
        id: 'KM',
        label: 'Comoros 🇰🇲',
      },
      {
        id: 'CG',
        label: 'Congo 🇨🇬',
      },
      {
        id: 'CD',
        label: 'Congo, Democratic Republic of 🇨🇩',
      },
      {
        id: 'DJ',
        label: 'Djibouti 🇩🇯',
      },
      {
        id: 'EG',
        label: 'Egypt 🇪🇬',
      },
      {
        id: 'GQ',
        label: 'Equatorial Guinea 🇬🇶',
      },
      {
        id: 'ER',
        label: 'Eritrea 🇪🇷',
      },
      {
        id: 'ET',
        label: 'Ethiopia 🇪🇹',
      },
      {
        id: 'GA',
        label: 'Gabon 🇬🇦',
      },
      {
        id: 'GM',
        label: 'Gambia 🇬🇲',
      },
      {
        id: 'GH',
        label: 'Ghana 🇬🇭',
      },
      {
        id: 'GN',
        label: 'Guinea 🇬🇳',
      },
      {
        id: 'GW',
        label: 'Guinea-Bissau 🇬🇼',
      },
      {
        id: 'CI',
        label: 'Ivory Coast',
      },
      {
        id: 'KE',
        label: 'Kenya 🇰🇪',
      },
      {
        id: 'LS',
        label: 'Lesotho 🇱🇸',
      },
      {
        id: 'LR',
        label: 'Liberia 🇱🇷',
      },
      {
        id: 'LY',
        label: 'Libya 🇱🇾',
      },
      {
        id: 'MG',
        label: 'Madagascar 🇲🇬',
      },
      {
        id: 'MW',
        label: 'Malawi 🇲🇼',
      },
      {
        id: 'ML',
        label: 'Mali 🇲🇱',
      },
      {
        id: 'MR',
        label: 'Mauritania 🇲🇷',
      },
      {
        id: 'MU',
        label: 'Mauritius 🇲🇺',
      },
      {
        id: 'MA',
        label: 'Morocco 🇲🇦',
      },
      {
        id: 'MZ',
        label: 'Mozambique 🇲🇿',
      },
      {
        id: 'NA',
        label: 'Namibia 🇳🇦',
      },
      {
        id: 'NE',
        label: 'Niger 🇳🇪',
      },
      {
        id: 'NG',
        label: 'Nigeria 🇳🇬',
      },
      {
        id: 'RW',
        label: 'Rwanda 🇷🇼',
      },
      {
        id: 'ST',
        label: 'Sao Tome and Principe',
      },
      {
        id: 'SN',
        label: 'Senegal 🇸🇳',
      },
      {
        id: 'SC',
        label: 'Seychelles 🇸🇨',
      },
      {
        id: 'SL',
        label: 'Sierra Leone 🇸🇱',
      },
      {
        id: 'SO',
        label: 'Somalia 🇸🇴',
      },
      {
        id: 'ZA',
        label: 'South Africa 🇿🇦',
      },
      {
        id: 'SS',
        label: 'South Sudan 🇸🇸',
      },
      {
        id: 'SD',
        label: 'Sudan 🇸🇩',
      },
      {
        id: 'SZ',
        label: 'Swaziland',
      },
      {
        id: 'TZ',
        label: 'Tanzania 🇹🇿',
      },
      {
        id: 'TG',
        label: 'Togo 🇹🇬',
      },
      {
        id: 'TN',
        label: 'Tunisia 🇹🇳',
      },
      {
        id: 'UG',
        label: 'Uganda 🇺🇬',
      },
      {
        id: 'ZM',
        label: 'Zambia 🇿🇲',
      },
      {
        id: 'ZW',
        label: 'Zimbabwe 🇿🇼',
      },
    ],
  },
  {
    id: 'asia',
    label: 'Asia',
    children: [
      {
        id: 'AF',
        label: 'Afghanistan 🇦🇫',
      },
      {
        id: 'BH',
        label: 'Bahrain 🇧🇭',
      },
      {
        id: 'BD',
        label: 'Bangladesh 🇧🇩',
      },
      {
        id: 'BT',
        label: 'Bhutan 🇧🇹',
      },
      {
        id: 'BN',
        label: 'Brunei 🇧🇳',
      },
      {
        id: 'MM',
        label: 'Burma (Myanmar) 🇲🇲',
      },
      {
        id: 'KH',
        label: 'Cambodia 🇰🇭',
      },
      {
        id: 'CN',
        label: 'China 🇨🇳',
      },
      {
        id: 'TL',
        label: 'East Timor 🇹🇱',
      },
      {
        id: 'IN',
        label: 'India 🇮🇳',
      },
      {
        id: 'ID',
        label: 'Indonesia 🇮🇩',
      },
      {
        id: 'IR',
        label: 'Iran 🇮🇷',
      },
      {
        id: 'IQ',
        label: 'Iraq 🇮🇶',
      },
      {
        id: 'il',
        label: 'Israel 🇮🇱',
      },
      {
        id: 'JP',
        label: 'Japan 🇯🇵',
      },
      {
        id: 'JO',
        label: 'Jordan 🇯🇴',
      },
      {
        id: 'KZ',
        label: 'Kazakhstan 🇰🇿',
      },
      {
        id: 'KP',
        label: 'Korea, North 🇰🇵',
      },
      {
        id: 'KR',
        label: 'Korea, South 🇰🇷',
      },
      {
        id: 'KW',
        label: 'Kuwait 🇰🇼',
      },
      {
        id: 'KG',
        label: 'Kyrgyzstan 🇰🇬',
      },
      {
        id: 'LA',
        label: 'Laos 🇱🇦',
      },
      {
        id: 'LB',
        label: 'Lebanon 🇱🇧',
      },
      {
        id: 'MY',
        label: 'Malaysia 🇲🇾',
      },
      {
        id: 'MV',
        label: 'Maldives 🇲🇻',
      },
      {
        id: 'MN',
        label: 'Mongolia 🇲🇳',
      },
      {
        id: 'NP',
        label: 'Nepal 🇳🇵',
      },
      {
        id: 'OM',
        label: 'Oman 🇴🇲',
      },
      {
        id: 'PK',
        label: 'Pakistan 🇵🇰',
      },
      {
        id: 'PH',
        label: 'Philippines 🇵🇭',
      },
      {
        id: 'QA',
        label: 'Qatar 🇶🇦',
      },
      {
        id: 'RU',
        label: 'Russian Federation 🇷🇺',
      },
      {
        id: 'SA',
        label: 'Saudi Arabia 🇸🇦',
      },
      {
        id: 'SG',
        label: 'Singapore 🇸🇬',
      },
      {
        id: 'LK',
        label: 'Sri Lanka 🇱🇰',
      },
      {
        id: 'SY',
        label: 'Syria 🇸🇾',
      },
      {
        id: 'TJ',
        label: 'Tajikistan 🇹🇯',
      },
      {
        id: 'TH',
        label: 'Thailand 🇹🇭',
      },
      {
        id: 'TR',
        label: 'Turkey 🇹🇷',
      },
      {
        id: 'TM',
        label: 'Turkmenistan 🇹🇲',
      },
      {
        id: 'AE',
        label: 'United Arab Emirates 🇦🇪',
      },
      {
        id: 'UZ',
        label: 'Uzbekistan 🇺🇿',
      },
      {
        id: 'VN',
        label: 'Vietnam 🇻🇳',
      },
      {
        id: 'YE',
        label: 'Yemen 🇾🇪',
      },
    ],
  },
  {
    id: 'europe',
    label: 'Europe',
    children: [
      {
        id: 'AL',
        label: 'Albania 🇦🇱',
      },
      {
        id: 'AD',
        label: 'Andorra 🇦🇩',
      },
      {
        id: 'AM',
        label: 'Armenia 🇦🇲',
      },
      {
        id: 'AT',
        label: 'Austria 🇦🇹',
      },
      {
        id: 'AZ',
        label: 'Azerbaijan 🇦🇿',
      },
      {
        id: 'BY',
        label: 'Belarus 🇧🇾',
      },
      {
        id: 'BE',
        label: 'Belgium 🇧🇪',
      },
      {
        id: 'BA',
        label: 'Bosnia and Herzegovina 🇧🇦',
      },
      {
        id: 'BG',
        label: 'Bulgaria 🇧🇬',
      },
      {
        id: 'HR',
        label: 'Croatia 🇭🇷',
      },
      {
        id: 'CY',
        label: 'Cyprus 🇨🇾',
      },
      {
        id: 'CZ',
        label: 'Czech Republic 🇨🇿',
      },
      {
        id: 'DK',
        label: 'Denmark 🇩🇰',
      },
      {
        id: 'EE',
        label: 'Estonia 🇪🇪',
      },
      {
        id: 'FI',
        label: 'Finland 🇫🇮',
      },
      {
        id: 'FR',
        label: 'France 🇫🇷',
      },
      {
        id: 'GE',
        label: 'Georgia 🇬🇪',
      },
      {
        id: 'DE',
        label: 'Germany 🇩🇪',
      },
      {
        id: 'GR',
        label: 'Greece 🇬🇷',
      },
      {
        id: 'HU',
        label: 'Hungary 🇭🇺',
      },
      {
        id: 'IS',
        label: 'Iceland 🇮🇸',
      },
      {
        id: 'IE',
        label: 'Ireland 🇮🇪',
      },
      {
        id: 'IT',
        label: 'Italy 🇮🇹',
      },
      {
        id: 'LV',
        label: 'Latvia 🇱🇻',
      },
      {
        id: 'LI',
        label: 'Liechtenstein 🇱🇮',
      },
      {
        id: 'LT',
        label: 'Lithuania 🇱🇹',
      },
      {
        id: 'LU',
        label: 'Luxembourg 🇱🇺',
      },
      {
        id: 'MK',
        label: 'Macedonia 🇲🇰',
      },
      {
        id: 'MT',
        label: 'Malta 🇲🇹',
      },
      {
        id: 'MD',
        label: 'Moldova 🇲🇩',
      },
      {
        id: 'MC',
        label: 'Monaco 🇲🇨',
      },
      {
        id: 'ME',
        label: 'Montenegro 🇲🇪',
      },
      {
        id: 'NL',
        label: 'Netherlands 🇳🇱',
      },
      {
        id: 'NO',
        label: 'Norway 🇳🇴',
      },
      {
        id: 'PL',
        label: 'Poland 🇵🇱',
      },
      {
        id: 'PT',
        label: 'Portugal 🇵🇹',
      },
      {
        id: 'RO',
        label: 'Romania 🇷🇴',
      },
      {
        id: 'SM',
        label: 'San Marino 🇸🇲',
      },
      {
        id: 'RS',
        label: 'Serbia 🇷🇸',
      },
      {
        id: 'SK',
        label: 'Slovakia 🇸🇰',
      },
      {
        id: 'SI',
        label: 'Slovenia 🇸🇮',
      },
      {
        id: 'ES',
        label: 'Spain 🇪🇸',
      },
      {
        id: 'SE',
        label: 'Sweden 🇸🇪',
      },
      {
        id: 'CH',
        label: 'Switzerland 🇨🇭',
      },
      {
        id: 'UA',
        label: 'Ukraine 🇺🇦',
      },
      {
        id: 'UK',
        label: 'United Kingdom 🇬🇧',
      },
      {
        id: 'VA',
        label: 'Vatican City 🇻🇦',
      },
    ],
  },
  {
    id: 'n. america',
    label: 'N. America',
    children: [
      {
        id: 'AG',
        label: 'Antigua and Barbuda 🇦🇬',
      },
      {
        id: 'BS',
        label: 'Bahamas 🇧🇸',
      },
      {
        id: 'BB',
        label: 'Barbados 🇧🇧',
      },
      {
        id: 'BZ',
        label: 'Belize 🇧🇿',
      },
      {
        id: 'CA',
        label: 'Canada 🇨🇦',
      },
      {
        id: 'CR',
        label: 'Costa Rica 🇨🇷',
      },
      {
        id: 'CU',
        label: 'Cuba 🇨🇺',
      },
      {
        id: 'DM',
        label: 'Dominica 🇩🇲',
      },
      {
        id: 'DO',
        label: 'Dominican Republic 🇩🇴',
      },
      {
        id: 'SV',
        label: 'El Salvador 🇸🇻',
      },
      {
        id: 'GD',
        label: 'Grenada 🇬🇩',
      },
      {
        id: 'GT',
        label: 'Guatemala 🇬🇹',
      },
      {
        id: 'HT',
        label: 'Haiti 🇭🇹',
      },
      {
        id: 'HN',
        label: 'Honduras 🇭🇳',
      },
      {
        id: 'JM',
        label: 'Jamaica 🇯🇲',
      },
      {
        id: 'MX',
        label: 'Mexico 🇲🇽',
      },
      {
        id: 'NI',
        label: 'Nicaragua 🇳🇮',
      },
      {
        id: 'PA',
        label: 'Panama 🇵🇦',
      },
      {
        id: 'KN',
        label: 'Saint Kitts and Nevis 🇰🇳',
      },
      {
        id: 'LC',
        label: 'Saint Lucia 🇱🇨',
      },
      {
        id: 'VC',
        label: 'Saint Vincent and the Grenadines 🇻🇨',
      },
      {
        id: 'TT',
        label: 'Trinidad and Tobago 🇹🇹',
      },
      {
        id: 'US',
        label: 'United States 🇺🇸',
      },
    ],
  },
  {
    id: 'oceania',
    label: 'Oceania',
    children: [
      {
        id: 'AU',
        label: 'Australia 🇦🇺',
      },
      {
        id: 'FJ',
        label: 'Fiji 🇫🇯',
      },
      {
        id: 'KI',
        label: 'Kiribati 🇰🇮',
      },
      {
        id: 'MH',
        label: 'Marshall Islands 🇲🇭',
      },
      {
        id: 'FM',
        label: 'Micronesia 🇫🇲',
      },
      {
        id: 'NR',
        label: 'Nauru 🇳🇷',
      },
      {
        id: 'NZ',
        label: 'New Zealand 🇳🇿',
      },
      {
        id: 'PW',
        label: 'Palau 🇵🇼',
      },
      {
        id: 'PG',
        label: 'Papua New Guinea 🇵🇬',
      },
      {
        id: 'WS',
        label: 'Samoa 🇼🇸',
      },
      {
        id: 'SB',
        label: 'Solomon Islands 🇸🇧',
      },
      {
        id: 'TO',
        label: 'Tonga 🇹🇴',
      },
      {
        id: 'TV',
        label: 'Tuvalu 🇹🇻',
      },
      {
        id: 'VU',
        label: 'Vanuatu 🇻🇺',
      },
    ],
  },
  {
    id: 's. america',
    label: 'S. America',
    children: [
      {
        id: 'AR',
        label: 'Argentina 🇦🇷',
      },
      {
        id: 'BO',
        label: 'Bolivia 🇧🇴',
      },
      {
        id: 'BR',
        label: 'Brazil 🇧🇷',
      },
      {
        id: 'CL',
        label: 'Chile 🇨🇱',
      },
      {
        id: 'CO',
        label: 'Colombia 🇨🇴',
      },
      {
        id: 'EC',
        label: 'Ecuador 🇪🇨',
      },
      {
        id: 'GY',
        label: 'Guyana 🇬🇾',
      },
      {
        id: 'PY',
        label: 'Paraguay 🇵🇾',
      },
      {
        id: 'PE',
        label: 'Peru 🇵🇪',
      },
      {
        id: 'SR',
        label: 'Suriname 🇸🇷',
      },
      {
        id: 'UY',
        label: 'Uruguay 🇺🇾',
      },
      {
        id: 'VE',
        label: 'Venezuela 🇻🇪',
      },
    ],
  },
],		
      }
  },
  methods: {
      ToMenu: function () {
         this.$router.push('/')
      },
	  
	  sendEmail() {
	     	     		 
	     const data = JSON.stringify({'q' : 'send_email', data: { 'name' : this.name, 'token' : this.token, 'id' : this.id, 'email' : this.new_email }})  
         axios.post(this.$hostname+'user-cards', "data="+data)
         .then(response => {             
			if (typeof response.data.alert.message !== 'undefined'){
			    this.mes = response.data.alert.message
			    this.message = this.dict[this.mes]
			}	
			
			if (typeof response.data.code !== 'undefined' && response.data.code == 69) {//69 код успешной отправки сообщения
			    localStorage.setItem('win_pin', 1)
				localStorage.setItem('email', this.new_email)
				this.email = this.new_email
                this.win_pin = 1	
                			
			}		
		   
         })
         .catch(err => {
           console.log(err)
         })
	  },
	  
	  sendPin() {
	     var pin = this.pin
		 		 
		 if(pin.length == 6){
		    const data = JSON.stringify({'q' : 'send_pin', data: { 'name' : this.name, 'token' : this.token, 'id' : this.id, 'email' : this.email, 'pin' : this.pin }})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {             
			   if (typeof response.data.alert.message !== 'undefined'){
			      this.mes = response.data.alert.message
			      this.message = this.dict[this.mes]
			   }	
			
			   if (typeof response.data.code !== 'undefined' && response.data.code == 70) {//70 код успешного подтверждения email
                  this.win_pin = 0 
				  this.look_email = 0
                  localStorage.setItem('win_pin', 0)				  
                  localStorage.setItem('email', this.new_email)	
                  localStorage.setItem('id', response.data.id)
                  localStorage.setItem('name', response.data.name)
                  localStorage.setItem('token', response.data.token)
                  localStorage.setItem('image', response.data.image)				  
			   }
		   
            })
            .catch(err => {
               console.log(err)
            })
		 }
	  
	  
	  },
	  
	  isEmpty: function (obj) {
           for (let key in obj) {
            // если тело цикла начнет выполняться - значит в объекте есть свойства
            return false;
           }
           return true;
      },
	  
	  setLingvo: function () {
	      console.log(this.valueCountry)
		  
		  const data = JSON.stringify({'q' : 'set_lingvo', data: { 'name' : this.name, 'token' : this.token, 'id' : this.id, 'email' : this.new_email, 'lingvo' : this.valueCountry }})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {             
			   if (typeof response.data.lang !== 'undefined') {
		                                			  
				    /*записываем полученный словарь*/
				    this.$store.commit('setLang', response.data.lang)				  
				    this.dict = this.$store.getters.getLang                 			  
				  
				    /*end*/		  
               
		        }
		   
            })
            .catch(err => {
               console.log(err)
            })
	  
	  },
	  
	  ToSkins() {
	      this.$router.push('/skins')
	  },
	  
	  
  },
  
  created(){
      
             
      /* доступы */          
      if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
      }
     
      if(localStorage.getItem('name') != null){
        this.name = localStorage.getItem('name')
      }
	 
	  if(localStorage.getItem('id') != null){
        this.id = localStorage.getItem('id')
      }
	 
	  if(localStorage.getItem('image') != null){
        this.image = localStorage.getItem('image')
      }
	  
	  if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
		this.look_email = 0
      }
	  
	  if(localStorage.getItem('win_pin') != null){
        this.win_pin = localStorage.getItem('win_pin')
      }
	  
	  	  
	  this.dict = this.$store.getters.getLang
	  if(this.isEmpty(this.dict)){ //если словарь пустой. запрашиваем
	        const data = JSON.stringify({'q' : 'get_lang', data: {'token' : this.token}})  
            axios.post(this.$hostname+'user-cards', "data="+data)
            .then(response => {
		        if (typeof response.data.lang !== 'undefined') {
		                                			  
				    /*записываем полученный словарь*/
				    this.$store.commit('setLang', response.data.lang)				  
				    this.dict = this.$store.getters.getLang                 			  
				    this.valueCountry = this.dict.lang
				    /*end*/					
               
		        }
		   
            })
            .catch(err => {
                console.log(err)
            })
	  }
	  /*end*/
	  
	  const dt = JSON.stringify({'q' : 'get_user_data', data: {'token' : this.token}})  
      axios.post(this.$hostname+'user-cards', "data="+dt)
      .then(response => {
		  if (typeof response.data.user_data !== 'undefined') {
		      /*записываем анкетные данные пользователя*/
					this.$store.commit('setUserData', response.data.user_data)				  
				    this.user_data = this.$store.getters.getUserData
					localStorage.setItem('image', this.user_data.image)
					this.image = this.user_data.image
                    this.stars_box = this.user_data.rang
					if(this.stars_box >= 6){
					    this.rank = this.dict.titan
					}
					
					if(this.stars_box == 5){
					    this.rank = this.dict.clairvoyant
					}
					
					if(this.stars_box == 4){
					    this.rank = this.dict.diviner
					}
					
					if(this.stars_box == 3){
					    this.rank = this.dict.extrasensory
					}
					
					if(this.stars_box == 2){
					    this.rank = this.dict.fortunate
					}
					
					if(this.stars_box == 1){
					    this.rank = this.dict.lucky
					}
                     					
					/*end*/
		  }		   
      })
      .catch(err => {
           console.log(err)
      })
				
				
      
      axios.post(this.$hostname+'user-cards')
        .then(response => {             
			if (typeof response.data.status_game !== 'undefined' && response.data.status_game == 0) {
			    this.$router.push('/headband')
			}		   
        })
        .catch(err => {
           console.log(err)
        })
  
  },
  
  watch: {
    // эта функция запускается при любом изменении в input country
    valueCountry: function () {
       this.setLingvo()
    },
    
    
  },
  
              
} 

</script>     
