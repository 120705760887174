<template>
  <div id="app">    
    <router-view/>
		 
  </div>
  
  
  
</template>


<script>
  
  import block_settings from './mixins/block_settings'
  
  export default {
    mixins: [block_settings],
	
    computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
    },
    created: function () {
      this.$http.interceptors.response.use(undefined, function (err) {
        return new Promise((resolve, reject) => {
          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.$store.dispatch("logout")
          }
          throw err;
        });
      });
	  
	  
    },
	
  }
</script>

<style>
@import './assets/css/style.css';



</style>
